.landSchemeMap {
    z-index: 10;
    position: relative;
}

.landSchemeMap path,
.landSchemeMap rect {
    fill: white;
    fill-opacity: 0.1;
    stroke: white;
    stroke-opacity: 0.1;
    stroke-width: 0.421451;
}

.landSchemeMap rect {
    width: 31.1874px;
    height: 31.1249px;
}
.landSchemeMap [data-land-type="0"] {
    fill: transparent;
}