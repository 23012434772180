.productsSectiontop {
  padding: 85px 220px 0 220px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
.productsSection {
  color: #fff;
  /* background:#F5F5F5; */
  margin-bottom: 85px;
  padding: 85px 69px 0 69px;
}
.productsSectionBody {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

@media (max-width: 1350px) {
  .productsSectiontop {
    padding: 45px 40px 0 40px;
  }
  .productsSection {
    padding: 45px 30px 0 30px;
  }
}
@media (max-width: 768px) {
  .productsSection {
    padding: 30px 15px 0 15px;
    margin-bottom: 0;
  }
  .productsSectiontop {
    padding: 30px 15px 0 15px;
  }
}
