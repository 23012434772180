.footer {
  margin-top: 50px;
  overflow: hidden;
  padding: 0 30px 30px 30px;
  color: #fff;
}
.footerArea {
  padding: 80px 0 0 0;
  border-radius: 15.587px;
  background: #1d212d;
}
.footerArea {
  background-image: url("../../assets/footerImg.png");
  background-repeat: no-repeat;
  background-position-x: -410px;
  background-position-y: 33%;
}
.footerTop {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logoContent {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 13px;
}
.logoContent .logo {
  /* transform: scale(1.95); */
  height: 70px !important;
}
.logoContent .groupSvg {
  height: 50px;
}
.footerTop p {
  font-size: 16px;
  font-weight: 500;
  margin-left: 90px;
}
.footerbody {
  margin-top: 100px;
  margin-bottom: 30px;
  display: flex;
  /* justify-content: space-evenly; */
  /* flex-basis: 50%; */
}
.footerBottom {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: space-between;
  padding: 30px 0 25px 0;
}
.footerBottomLeft,
.footerBottomRight {
  flex: 50%;
  display: flex;
  justify-content: center;
}
.footerBottomRight {
  display: none;
}
.footerBottomRight a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  padding-left: 10px;
}
.footerBottomRight a:hover {
  color: #eb1153;
}
.footerBottomLeft p {
  margin-left: 115px;
}
.footerNav {
  flex: 50%;
  display: flex;
  justify-content: center;
}
.footerNav ul {
  list-style-type: none;
}
.footerNav li:nth-child(1) {
  margin-top: 0;
}
.footerNav li:last-child {
  margin-bottom: 0;
}
.footerNav li {
  cursor: pointer;
  margin: 20px 0;
}
.footerNav li .footerLinks {
  font-size: 40px;
  color: inherit;
  text-decoration: none;
}

.footerNav li .footerLinks:hover {
  color: #eb1153;
}

.footerInfo {
  flex: 55%;
  display: flex;
  flex-direction: column;
  /* row-gap: 78px; */
  justify-content: space-between;
}

.footerInfo a {
  color: #fff;
  text-decoration: none;
}
.footerInfo a:hover {
  color: #eb1153;
}
.footerInfoRow {
  display: flex;
  column-gap: 100px;
}
.compInfo,
.contact,
.address,
.socialLinks {
  max-width: 215px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}
.footerInfoRow div h5 {
  font-size: 22.86px;
}
.socialLinksImg span {
  height: 32px;
  width: 33px;
}

.socialLinksImg span img {
  height: 100%;
  width: 100%;
}
.socialLinksImg {
  display: flex !important;
  column-gap: 26px;
}
.phoneIcon,
.addressIcon,
.socialLinksMobile {
  display: none;
}
.mobilefotterLogo {
  display: none;
}
@media (max-width: 1024px) {
  .footerInfo {
    padding-right: 30px;
  }
  .footerInfoRow {
    column-gap: 50px;
  }
}
@media (max-width: 768px) {
  .footerArea {
    padding: 30px 0 0 0;
  }
  .footerbody {
    margin-top: 30px;
  }
  .footerInfoRow div h5 {
    display: none;
  }
  .footerNav li .footerLinks {
    font-size: 16px;
  }
  .footer {
    padding: 0 15px 15px 15px;
  }
  .logoContent .logo {
    display: none;
  }
  .mobilefotterLogo {
    display: block;
  }
  .footerTop p {
    display: none;
  }
  .logoContent .groupSvg {
    display: none;
  }
  .compInfo {
    display: none;
  }
  .contact,
  .address {
    flex-direction: row;
    column-gap: 15px;
    align-items: center;
  }
  .phoneIcon,
  .addressIcon,
  .socialLinksMobile {
    display: block;
  }
  .email,
  .socialLinks {
    display: none;
  }
  .footerInfoRow {
    flex-direction: column;
  }
  .footerInfoRow p {
    font-size: 12px;
    display: flex;
    align-items: center;
    opacity: 0.7;
  }
  .socialLinksMobile span svg {
    height: 23px;
    width: 23px;
  }
  .socialLinksMobile span {
    height: 42px;
    width: 42px;
    padding: 9px;
    border-radius: 10px;
    background: rgba(196, 196, 196, 0.2);
  }
  .footerBottom {
    flex-direction: column;
    padding: 15px 15px;
    row-gap: 5px;
  }
  .footerBottomLeft p {
    color: rgba(255, 255, 255, 0.3);
    margin-left: 0;
    text-align: center;
  }
  .footerArea {
    background-image: url("../../assets/footerImgMobile.png");
    background-position: center calc(100% - 85px);
    background-size: contain;
  }
}
@media (max-width: 430px) {
  .footerBottomLeft p,
  .footerBottom a {
    font-size: 12px;
  }
  .footer {
    /* padding: 0 15px 15px 15px; */
    margin-top: 30px;
  }
  .footerInfo {
    padding-right: 20px;
  }
  .compInfo {
    display: none;
  }
}
@media (max-width: 350px) {
  .footerInfo {
    padding-right: 11px;
  }
  .socialLinksImg {
    column-gap: 15px;
  }
}

.socialLinksImg a:hover svg path ::-ms-fill {
  color: #eb1153;
}
