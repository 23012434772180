.wrapper {
    padding: 35px 0;
    border-top: 1px solid rgba(29, 45, 29, 0.10);
    border-bottom: 1px solid rgba(29, 45, 29, 0.10);
}

.accardionHead {
    display: flex;
    justify-content: space-between;
}

.questionContainer {
    display: flex;


}

.questionContent {
    color: #1D212D;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    /* 21.6px */
    cursor: pointer;
}

.accardionTogglerPlus {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.answerContainer {
    transition: visibility 0s, height 0.5s, opacity 0.5s, margin-top 0.5s linear;
}

.answerContent {
    color: #1D212D;
    font-family: "AeonikTRIALLight";
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 90%;
    /* 19.8px */
    max-width: 75%;
}

@media (max-width:430px) {
    .questionContent {
        font-size: 14px;
    }

    .accardionHead {
        align-items: center
    }

    .answerContent {
        font-size: 14px;
    }

    .wrapper {
        padding: 16px 0;
    }

    .questionContainer svg {
        width: 22px;
        height: 22px;
    }
}

/* Стили для цвета fill внутри SVG при ховере */
.accardionTogglerPlus path {
    fill: #E8EAE8;
    /* Исходный цвет fill */
}

.accardionTogglerPlus:hover path {
    fill: #EB1153;
    /* Новый цвет fill при ховере */
}