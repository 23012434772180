.communitySection {
    margin-top: 43px;
    padding: 8px 65px 18px 65px;
    color: #1D212D;
}
.communityConent {
    display: flex;
    justify-content: space-between;
}
.communityConentTitle {
    color: #EB1153;
    /* font-family: "AeonikTRIALRegular"; */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 18px */
}
.communityConentTitle::before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 7px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    /* background-color: #3FAA0B; */
    -webkit-animation: glowing 1300ms infinite;
    -moz-animation: glowing 1300ms infinite;
    -o-animation: glowing 1300ms infinite;
    animation: glowing 1300ms infinite;
}
@-webkit-keyframes glowing {
    0% {
        background-color: #3FAA0B;
        -webkit-box-shadow: 0 0 3px #0091b2;
    }
    50% {
        background-color: #409217;
        -webkit-box-shadow: 0 0 15px #409217;
    }
    100% {
        background-color: #398812;
        -webkit-box-shadow: 0 0 3px #398812;
    }
}
@keyframes glowing {
    0% {
        background-color: #3FAA0B;
        box-shadow: 0 0 3px #3FAA0B;
    }
    50% {
        background-color: #409217;
        box-shadow: 0 0 15px #409217;
    }
    100% {
        background-color: #398812;
        box-shadow: 0 0 3px #398812;
    }
}
.communityConentLeft h1{
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%; /* 45px */
    margin-top: 70px;
}
.communityConentRight {
    width: 60%;
}
.accordion {

}
.mobileTitle {
    display: none;
    font-size: 24px;
    font-weight: 400;
    line-height: 90%; /* 45px */
    margin-top: 23px;
}
@media (max-width:1350px) {
    .communitySection {
        padding: 8px 30px 18px 30px;
    }
}
@media (max-width:1150px){
    .communityConent {
        flex-direction: column;
        row-gap: 30px;
    }
    .title {
        display:none
    }
    .mobileTitle {
        display: block;
    }
    .communityConentRight {
        width: 100%;
    }
}
@media (max-width:768px) {
    .communitySection {
        padding: 8px 15px 18px 15px;
    }
    .questionContent {
        font-size: 14px !important;
    }
    .communityConentLeft h1 {
        margin-top: 23px;
        font-size: 24px;
    }
}