.stayInKnowContentBoxSecond {
    /* padding: 0 32px 37px 32px; */
    /* height: 700px; */
    width: 32%;
    display: flex;
    flex-direction: column;
}
.stayInKnowContentBoxSecond:hover .stayInKnowbottomText{
    color:#EB1153
}
.stayInKnowContentBoxSecond:hover{
    box-shadow: 0px 10px 30px -8px rgba(0,0,0,0.1);
}
.stayInKnowTop {
    /* background: url("../../assets/Boreal-Beauty.png") , cover no-repeat;
    width: 100%; */
    min-height: 470px;
    max-height: 497px;
    cursor: pointer;
}
.stayInKnowTop img {
    height: 100%;
    width: 100%;
}
.stayInKnowbottom {
    padding-top: 33px;
    background: #F1F1F1;
    padding: 33px 32px 40px 32px;
}
.stayInKnowbottomText {
    color: #1D212D;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 35px;
    cursor: pointer;
}
.stayInKnowbottomContent {
    display: flex;
    justify-content: space-between;
}
.stayInKnowbottomContent p {
    color: #3FAA0B;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
}
.readeMoreBox {
    display: flex;
    align-items:center;
}
/* 
.readeMoreBox a {
    color: #1D212D;
    display: flex;
    align-items:center;
    column-gap:28px
}
.readeMoreBox:hover a {
    color: #EB1153
} */
.contactUs {
    display: flex;
    align-items:center;
}
.contactUsBtn {
    display: flex;
    align-items:center;
    column-gap:28px;
    color: #1D212D;
    text-decoration: none;
}
.arrowspan {
    height: 49px;
    width: 49px;
    border-radius: 50%;
    background-color:#FFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contactUsBtn:hover {
    color:#EB1153
}
.contactUsBtn:hover .arrowspan {
    background-color: #EB1153
} 

.contactUsBtn svg path {
    fill:black
}
.contactUsBtn:hover svg path {
    fill: #FFF;
}
@media (max-width:1350px) {
    .stayInKnowContentBoxSecond {
        width: 31%;
        min-width: 31%;
        /* min-height: 441px; */
    }
}

@media (max-width:1210px) {
    .stayInKnowTop img {
        border-top-right-radius: 10px;
        border-top-left-radius:10px
    }
    .stayInKnowbottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: -webkit-fill-available;
        padding: 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .stayInKnowbottomText {
        font-size: 14px;
        margin-bottom: 28px;
    }
    .stayInKnowbottomContent p {
        font-size: 10px;
    }
    .contactUsBtn {
        font-size: 12px;
        column-gap:17px
    }
    .arrowspan {
        width: 40px;
        height: 40px;
    }
}
@media (max-width:820px) {
    .stayInKnowbottom {
        padding: 10px;
    }
    .stayInKnowContentBoxSecond {
        min-width: 47%;
    }

}
@media (max-width:550px) {
    .stayInKnowContentBoxSecond {
        min-width: 100%;
    }

}
