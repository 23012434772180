.title {
  font-size: 20px;
}

.header {
  min-height: 620px;
  position: relative;
  /* width: 1920px; */
  /* height: 122px; */
  /* padding: 21px 47px 21px 47px; */
}

.header .drawer__list {
  border: 1px solid black;
}

.header > img {
  width: 100%;
  height: 100vh;
}

.headerArea {
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  padding: 21px 47px 21px 47px;
  background: #297206a3;
}

.headerAreas {
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  padding: 21px 47px 21px 47px;
}

.headVideo {
  /* position: absolute; */
  width: 100%;
}

.homeHeaderArea {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.HeaderAreas {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.headerTopContent {
  /* height: 122px; */
  /* padding: 19px 0; */
  display: flex;
  justify-content: space-between;
}

.langbox > .active_lang {
  display: block;
  color: red !important;
}

/* .logo {
    height: 18px;
    width: 18px;
} */
.logoContent {
  /* padding: 17px 18px; */
  display: flex;
  align-items: center;
  /* column-gap:7px ; */
}

.logoContent:hover .logo svg {
  transition: all 0.9s ease-in-out;
}

.logoContent .logo svg {
  height: 40px;
  width: 30px;
}

.logoContent:hover .logo svg {
  transform: rotate(-160deg);
}

.groupSvg svg g path {
}

.navContent {
  padding: 19px 14px;
  display: flex;
  gap: 41px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.linksBox {
  display: flex;
  column-gap: 30px;
  align-items: center;
}

.headerLinks {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  /* color: #FFF; */
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.3s ease-in-out;
}

.headerLinks.active {
  color: #eb1153;
  transform: scale(1.05);
}

.navContent .linksBox .headerLinks:hover {
  color: #eb1153;
  transform: scale(1.05);
}

.langbox {
  display: flex;
  align-items: center;
  column-gap: 17px;
  padding: 0 41px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.langbox span {
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: none;
  transition: 0.3s ease-in-out;
}

.navContent .langbox span:hover {
  color: #eb1153;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  transform: scale(1.05);
}

.navContent .getInTouch {
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out;
}

.navContent .getInTouch:hover {
  color: #eb1153;
  transform: scale(1.05);
}

.navContent .getInTouch a {
  color: #fff;
  text-decoration: none;
}

.navContent .getInTouch a:hover {
  color: #eb1153;
  transform: scale(1.05);
}

.headerMainContent {
  /* position: absolute; */
  /* left: 57px; */
  /* bottom: 77px; */
  display: flex;
  height: 270px;
}

.pageTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: capitalize;
  color: #fff;
  font-size: 120px;
  text-align: center;
  font-weight: 400;
  line-height: 90%;
}

.discoverFreshness {
  max-width: 638px;
}

.discoverFreshness h1 {
  font-size: 90px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  width: min-content;
  color: white;
}

.headerMainContentRight {
  padding-left: 188px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 906px;
  margin-bottom: 5px;
}

.headerMainContentRight p {
  color: white;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.aboutUsBtn {
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  width: 165px;
  height: 60px;
  border-radius: 1000px;
  background: rgba(255, 255, 255, 0.3);
  padding: 20px 25px;
  transition: 0.3s ease-in-out;
  text-decoration: none;
}
.aboutUsBtn span {
  width: max-content;
}

.aboutUsBtn:hover {
  background: #eb1153;
}

.rightContent {
  display: flex;
  column-gap: 13px;
  align-items: center;
}

.ractangle {
  display: none;
}

.burgerMenuBtn {
  display: none;
  cursor: pointer;
}

@media (max-width: 1350px) {
  /* .homeHeaderArea { */
  /* justify-content: unset; */
  /* row-gap:100px */
  /* } */

  .headerMainContent {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .discoverFreshness h1 {
    width: max-content;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
  }

  .headerMainContent .headerMainContentRight {
    gap: 25px;
    text-align: center;
    padding: 0;
    align-items: center;
  }

  .headerMainContentRight p {
    font-size: 18px;
  }

  .headerArea {
    padding: 15px 20px 21px 20px;
  }

  .headerAreas {
    padding: 15px 20px 21px 20px;
  }

  .logoContent {
    padding: 17px 10px;
  }

  .logoContent .logo {
    height: 28px;
    width: 28px;
  }

  .logoContent svg:nth-child(2) {
    width: 113px;
  }

  .logoContent .logo svg {
    width: 28px;
    height: 28px;
  }

  .linksBox {
    column-gap: 20px;
  }

  .langbox {
    column-gap: 14px;
    padding: 0 20px;
  }

  .navContent {
    padding: 19px 10px;
    gap: 20px;
  }

  .headerLinks {
    font-size: 14px;
  }

  .navContent .getInTouch {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .navContent {
    gap: 15px;
  }

  .linksBox {
    column-gap: 15px;
  }

  .langbox {
    column-gap: 10px;
    padding: 0 15px;
  }
}

@media (max-width: 935px) {
  .headerArea {
    padding: 15px 10px 21px 10px;
  }

  .headerAreas {
    padding: 15px 20px 21px 20px;
  }

  .navContent {
    gap: 10px;
  }

  .linksBox {
    column-gap: 10px;
  }

  .langbox {
    column-gap: 10px;
    padding: 0 10px;
  }

  .langbox span {
    font-size: 14px;
  }

  .logoContent {
    padding: 15px 0;
  }

  .navContent {
    padding: 15px 0;
  }
}

@media (max-width: 850px) {
  .headerArea {
    padding: 15px 10px 21px 10px;
  }

  .headerAreas {
    padding: 15px 10px 21px 10px;
  }

  .navContent {
    gap: 7px;
    padding: 15px 0;
  }

  .linksBox {
    column-gap: 7px;
  }

  .langbox {
    column-gap: 7px;
    padding: 0 10px;
  }

  .langbox span {
    font-size: 12px;
  }

  .logoContent {
    padding: 15px 0;
  }

  .navContent .getInTouch {
    font-size: 13px;
  }

  .headerLinks {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .header {
    min-height: 324px;
  }

  .header img {
    max-height: 324px;
  }

  .homeHeaderArea {
    max-height: 324px;
    /* background-image:url("../../assets//homeMobileImg.png") !important */
  }

  .HeaderAreas {
    max-height: 324px;
  }

  .logoContent {
    padding: 0;
  }

  .headerLinks {
    font-size: 18px;
  }

  video {
    display: none;
  }

  .headerArea {
    padding: 15px 10px 21px 10px;
  }

  .headerAreas {
    padding: 15px 10px 21px 10px;
  }

  .navContent {
    border-bottom: none;
    /* border-right: 1px solid  rgba(255, 255, 255, 0.30); */
    /* padding: 0 15px; */
  }

  .headerMainContent {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .discoverFreshness h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
  }

  .headerMainContent .headerMainContentRight {
    gap: 25px;
    text-align: center;
    padding: 0;
    align-items: center;
  }

  .headerMainContentRight p {
    font-size: 12px;
  }

  .linksBox {
    display: none;
  }

  .langbox {
    display: none;
  }

  .langbox span {
    font-size: 12px;
  }

  .ractangle {
    display: block;
  }

  .burgerMenuBtn {
    display: block;
    cursor: pointer;
  }

  .pageTitle {
    font-size: 35px;
    width: 100%;
  }

  .headerMainContent {
    margin-top: 45px;
  }
}

@media (max-width: 430px) {
  .headerArea {
    padding: 15px 15px 21px 15px;
  }

  .headerAreas {
    padding: 15px 15px 21px 15px;
  }

  .headerMainContent {
    margin-top: 45px;
  }

  .headerMainContentRight p {
    font-size: 12px;
  }

  .aboutUsBtn {
    padding: 13px 25px;
    font-size: 12px;
  }

  .pageTitle {
    font-size: 35px;
  }
}
