.modal{
 position: fixed;
 top: 0;
 left: 0;
 bottom: 0;
 right: 0;
 background: #000000a2;
 display: flex;
 align-items: center;
 justify-content: center;
 z-index: 9999;
}
.modal__container iframe{
  width: 900px;
  height: 500px;
}
@media (max-width:1350px) {
  .modal__container iframe{
    max-width: 700px;
  }
}
@media (max-width:730px) {
  .modal__container iframe{
    max-width: 600px;
  }
}
@media (max-width:615px) {
  .modal__container iframe{
    max-width: 500px;
  }
}
@media (max-width:529px) {
  .modal__container iframe{
    max-width: 300px;
  }
}