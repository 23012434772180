.stayInKnowContentBox {
    background-image: url(../../../assets/subscribe_bg.png);
    background-size: cover;
    background-repeat:no-repeat ;
    padding: 32px 32px 37px 32px;
    /* height: 700px; */
    height: inherit;
    width: 32%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #F5F5F5;
}

.boxTop {
    /* margin-bottom: 275px; */
}

.boxTitle{
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
}
.boxText {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.formBox {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}
::placeholder {
    color: rgba(255, 255, 255, 0.30);
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.inputBox {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
}
.inputBoxCheckbox {
    display: flex;
    column-gap: 12px;
    max-width: 300px;
}
.inputBox label {
    font-family: "AeonikTRIALLight";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.inputBox input {
    padding: 5px;
    color: rgba(255, 255, 255, 0.30);
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.30);
    max-width: 335px;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;
}
.inputBoxCheckbox label {
    font-family: "AeonikTRIALLight";
    opacity: 0.8;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.inputBoxCheckbox input {
    position: relative;
    cursor: pointer;
    /* width: 1.9rem;
    height: 1.9rem;
    accent-color: #008210; */
}
.inputBoxCheckbox input:before {
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    top: 5px;
    left: 0;
    border: 1px solid #99AFC1;
    border-radius: 3px;
    padding: 1px;
    background-color: #008210;
  }
 .inputBoxCheckbox input[type=checkbox]:checked:before {
    background-color: #008210;
  }
 .inputBoxCheckbox input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 7px;
    left: 7px;
}
.formButton {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 20px 25px;
    border-radius: 1000px;
    background: rgba(255, 255, 255, 0.30);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap:16px;
    transition: all 300ms ease-out;
}
.formButton:hover {
    background: #EB1153;
}
@media (max-width:1350px) {
    .stayInKnowContentBox {
        width: 31%;
        min-width: 31%;
        /* min-height: 441px; */
    }
}
@media (max-width:1210px) {
    .stayInKnowContentBox {
        border-radius:10px;
        padding: 30px;
    }
    .boxTitle{
        font-size: 24px;
        margin-bottom: 15px;
    }
    .boxText {
        font-size: 14px;
    }
    .inputBox label {
        font-size: 11px;
    }
    .inputBox input {
        font-size: 22px;
    }
    ::placeholder {
        font-size: 22px !important;
    }
    .inputBoxCheckbox label {
        font-size: 11px !important;
    }
    .inputBoxCheckbox input:before {
        width: 15px;
        height: 15px;
        top: 6px;
    }
    .inputBoxCheckbox {
        column-gap: 10px
    }
    .btnBox {
        display: flex;
        justify-content: center;
    }
    .formButton {
        font-size: 12px;
        padding: 13px 16px;
    }
}
@media (max-width:820px) {
    .stayInKnowContentBox {
        min-width: 100%;
        min-height: 390px;
    }
}
@media (max-width:430px) {
    .stayInKnowContentBox {
        min-width: 100%;
    }

}
