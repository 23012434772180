@font-face {
  font-family: "MontserratBold";
  src:
    url("./assets/fonts/Montserrat-Bold.ttf") format("truetype"),
    local("Aeonik") format("truetype");
  /* font-weight: bold; */
  /* font-display: optional; */
}
@font-face {
  font-family: "MontserratBboldItalic";
  src: url("./assets/fonts/Montserrat-BoldItalic.ttf") format("truetype");
  /* font-display: optional; */
}
@font-face {
  font-family: "MontserratRegular";
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
  /* font-display: optional; */
}
@font-face {
  font-family: "MontserratItalic";
  src: url("./assets/fonts/Montserrat-Italic.ttf") format("truetype");
  /* font-display: optional; */
}
@font-face {
  font-family: "MontserratLight";
  src: url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
  /* font-display: optional; */
}
