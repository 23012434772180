.refrigeratorsSection {
    /* padding: 85px 69px; */
    color: #1D212D;
    background:#F5F5F5;
    margin-bottom: 85px;
}
.content {
    display: flex;
    column-gap: 30px;
    padding: 85px 69px 0 69px;
}
.contentLeft {
    display:flex;
    flex-direction: column;
    row-gap: 30px;
    width: 67%;
}
.contentLeftTitle {
    font-size: 36px;
    font-style: normal;
    font-family: "AeonikTRIALBold";
    font-weight: 700;
    line-height: 100%;
}
.contentLeftText {
    font-size: 20px;
    line-height: 100%; /* 20px */
}
.contentRight {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    /* row-gap: 35px; */
    width: 33%;
}
.contentRight img {
    /* height: -webkit-fill-available; */
    width: 100%;
}
.contentRightThirdImg {
    display: none;
}
.contentRight .contentLeftTitle {
    display: none;
}
@media (max-width:1350px) {
    .content {
        padding: 85px 30px 0 30px;
        /* flex-direction: column; */
        row-gap: 30px;
    }
    .flexImgBlock img {
        height: unset;
    }
}
@media (max-width:1200px) {
    .contentLeft .contentLeftTitle {
        display: none;
    }
    .contentRight .contentLeftTitle {
        display: block;
    }
    .content {
        flex-direction: column-reverse;
        /* row-gap: 30px; */
    }
    .contentLeft, .contentRight {
        width: 100%;
    }
    .contentRight {
        row-gap: 30px;
    }
    .contentLeft img {
        display: none;
    }
    .flexImgBlock {
        overflow: hidden;
        display: flex;
        column-gap: 30px;
        max-width: 100%;
    }
    .contentRightSecondImg {
        /* max-width: 50%; */
    }
    .flexImgBlock img {
        height: unset;
        width: 48%;
    }
    .contentRightThirdImg {
        display: block;
        /* max-width: 50%; */
    }
}
@media (max-width:768px) {
    .contentRight {
        row-gap: 20px;
    }
    .flexImgBlock {
        column-gap: 20px;
    }
    .content {
        padding: 85px 15px 0 15px;
    }
}
@media (max-width:430px) {
    .contentLeftTitle {
        font-size: 16px;
    }
}