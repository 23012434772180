.blogSingleSection {
    padding: 85px 0 85px 0;
}
.blogSingleContent {
    /* text-align: center; */
    max-width: 60%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
}
.contentTitle h1 {
    font-size: 40px;
    line-height: 100%; /* 40px */
}
.contentText {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    font-size: 20px;
    line-height: 100%; /* 20px */
}
.contentTitle {
    font-weight: 700;
    color: #1D212D;
    font-family: "AeonikTRIALBoldL";
}
.imgBlock {
    display: flex;
    column-gap: 27px;
}
.imgBlock img:nth-child(1){
    max-width: 65%;
}
.imgBlock img:nth-child(2){
    max-width: 35%;
}
@media (max-width:1000px) {
    .blogSingleSection {
        padding: 30px;
    }
    .imgBlock img:nth-child(1){
        max-width: 60%;
    }
    .blogSingleContent {
        max-width: 100%;
    }
}
@media (max-width:768px) {
    .blogSingleSection {
        padding: 30px 15px;
    }
    .imgBlock {
        flex-direction: column;
        row-gap: 20px;
    }
    .imgBlock img:nth-child(1){
        max-width: 100%;
    }
    .imgBlock img:nth-child(2){
        max-width: 100%;
    }
    .blogSingleContent {
        max-width: 100%;
    }
    
}