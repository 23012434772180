.nothfound{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1{
        font-size: 100px;
    }
    p{

        font-size: 20px;
    }

}