.drawer {
  width: 100%;
  height: 100vh;
  /* background-color: transparent; */
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 99999;
  background-image: url("../../assets//burgerImg.jpg") !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.drawer__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px 0px;
  color: #fff;
}

.getInTouch a {
  color: #fff;
  font-size: 13px;
}
.drawer .drawer__list {
  height: 400px;
  margin: 59px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.drawer .drawer__list > a {
  width: max-content;
}
.drawer .drawer__list a:hover {
  color: #eb1153;
}
.drawer_lang {
  max-width: 168px;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 46px auto;
}
.drawer_lang span {
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
.drawer_lang span:hover {
  color: #eb1153;
}
