.brandsSection{
    margin-top:50px;
    height: 40px;
}
.slick-arrow{
    display: none!important;
    
}
.slick-slider {
    height: 40px!important;
}