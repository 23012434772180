.readyToTakeSection {
  padding: 0 65px;
  color: #fff;
  margin: 65px 0px 40px;
}
.readyToTakeContent {
  padding: 85px 150px;
  display: flex;
  justify-content: space-between;
  /* background: green; */
  background-image: url("../../assets/home/form_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.readyToTakeContentLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.readyToTakeContentLeft h1 {
  font-size: 55.875px;
  font-style: normal;
  max-width: 700px;
  font-weight: 700;
  line-height: 100%; /* 55.875px */
  text-transform: uppercase;
}
.contactUs {
  display: flex;
  align-items: center;
}
.contactUsBtn {
  display: flex;
  align-items: center;
  column-gap: 28px;
  color: #fff;
  text-decoration: none;
}
.arrowspan {
  height: 49px;
  width: 49px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactUsBtn:hover {
  color: red;
}
.contactUsBtn:hover .arrowspan {
  background-color: #eb1153;
}

.contactUsBtn svg path {
  fill: black;
}
.contactUsBtn:hover svg path {
  fill: #fff;
}

.readyToTakeContentRightTitle {
  font-size: 29.582px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 43px;
}
.formBox {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
.inputBox p {
  color: #eb1153;
}

.terms {
  color: #eb1153;
  margin-top: -24px;
}

::placeholder {
  color: rgba(255, 255, 255, 0.3);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.inputBox {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
.inputBoxCheckbox input {
  height: 0;
}
.inputBox input,
.inputBox textarea {
  padding: 5px;
  color: #fff;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  max-width: 380px;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  resize: none;
}
.inputBoxCheckbox {
  display: flex;
  column-gap: 20px;
  max-width: 300px;
}
.inputBox label {
  /* opacity: 0.8; */
  font-family: "AeonikTRIALLight";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.inputBox input {
  padding: 5px;
  color: rgba(255, 255, 255, 0.3);
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  max-width: 335px;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
}

.inputBoxCheckbox label {
  font-family: "AeonikTRIALLight";
  opacity: 0.8;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.inputBoxCheckbox input {
  position: relative;
  cursor: pointer;
  /* width: 1.9rem;
    height: 1.9rem;
    accent-color: #008210; */
}
.inputBoxCheckbox input:before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  top: 5px;
  left: 0;
  border: 1px solid #99afc1;
  border-radius: 3px;
  padding: 1px;
  background-color: #008210;
}
.inputBoxCheckbox input[type="checkbox"]:checked:before {
  background-color: #008210;
}
.inputBoxCheckbox input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 7px;
  left: 7px;
}
.formButton {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 20px 25px;
  border-radius: 1000px;
  background: rgba(255, 255, 255, 0.3);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  transition: all 300ms ease-out;
  cursor: pointer;
  min-width: 174px;
}
.formButton:hover {
  background: #eb1153;
}
.mobiletitle {
  display: none;
}
@media (max-width: 1350px) {
  .readyToTakeSection {
    padding: 0 30px;
  }
  .readyToTakeContent {
    padding: 85px 30px;
    column-gap: unset;
    justify-content: space-between;
  }
  .readyToTakeContentLeft h1 {
    max-width: unset;
    font-size: 45px;
  }
}
@media (max-width: 1024px) {
  .readyToTakeContent {
    padding: 40px 30px;
    column-gap: unset;
    justify-content: space-between;
  }
  .readyToTakeContentLeft h1 {
    font-size: 24px;
  }
  .readyToTakeContentRightTitle {
    font-size: 18px;
  }
  .arrowspan {
    height: 40px;
    width: 40px;
  }
}
@media (max-width: 768px) {
  .readyToTakeSection {
    padding: 0 15px;
  }
  .readyToTakeContentRightTitle {
    font-size: 16px;
  }
  .formButton {
    color: #fff;
    font-size: 16px;
    padding: 13px 16px;
  }
  .readyToTakeContent {
    background-image: url("../../assets/mobileFormBg.png");
  }
}
@media (max-width: 620px) {
  .title {
    display: none;
  }
  .mobiletitle {
    display: block;
  }
  .readyToTakeContent {
    flex-direction: column;
    padding: 27px;
    row-gap: 27px;
  }
  .readyToTakeContentLeft {
    row-gap: 20px;
  }
  .contactUs {
    justify-content: end;
  }
  .contactUsBtn {
    column-gap: 10px;
  }
  .readyToTakeContentRightTitle {
    margin-bottom: 30px;
  }
  .inputBox label {
    font-size: 10px;
  }
  .inputBox input {
    font-size: 18px;
  }
  ::placeholder {
    font-size: 18px;
  }
  .inputBoxCheckbox {
    column-gap: 10px;
  }
  .inputBoxCheckbox input:before {
    height: 11px;
    width: 11px;
  }
  .inputBoxCheckbox label {
    font-size: 10px;
  }
  .formButton {
    padding: 13px 16px;
    font-size: 12px;
    margin: 0 auto;
  }
  .formButton svg {
    width: 15px;
  }
}
