.productsSection {
  padding: 85px 69px 0 69px;
}
.content {
  /* padding: 85px 69px 0 69px; */
}

.contentAboutInfo {
  /* position: relative; */
  border-radius: 15.587px;
  /* background: #1D212D; */

  display: flex;
  column-gap: 76px;
  padding: 40px;
  color: #1d212d;
}
.content:nth-child(even) .contentAboutInfo {
  flex-direction: row-reverse;
}

/* .contentAboutInfo:nth-child(odd){
    flex-direction: row-reverse;
    background: #1D212D;
} */
.contentLeft {
  /* position: absolute; */
  /* background-image: url("../../../assets/peach.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 30px;
}

.contentRight {
  /* padding: 102px 112px; */
  max-width: 70%;
  margin-left: 30px;
}
.infoBox {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}
.rightTop {
  display: flex;
  align-items: center;
  justify-content: end;
}
.fruitAbout {
  text-align: center;
}
.fruit {
  font-size: 60px;
  line-height: 90%; /* 54px */
}
.fruitSort {
  font-size: 50px;
  font-weight: 300;
  line-height: 90%; /* 45px */
  font-family: "AeonikTRIALLight";
  margin-top: 10px;
}
.orchardSize {
  color: #ffffffbf;
  font-family: "AeonikTRIALLight";
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 300;
  line-height: 120%; /* 16.8px */
  margin-top: 14px;
  color: #1d212d;
}
.imgbox {
  max-height: 190px;
  height: 100%;
  /* width: 500px; */
  margin: 20px;
}
.imgbox img {
  height: 100%;
  transform: translate(-15%, -15%);
  /* width: 100%; */
  /* width: 173px;
    height: 193px; */
}
.contentLeft img {
  /* display: none; */
}
html {
  --scrollbarBG: #3c3c3c;
  --thumbBG: #90a4ae;
}
.descr {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 20px */
  --scrollbarBG: #3c3c3c;
  --thumbBG: #90a4ae;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.descr h3 {
  margin-bottom: 20px;
}
.descr::-webkit-scrollbar {
  width: 10px;
  border-radius: 20px;
}
.descr::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
  border-radius: 20px;
}
.descr::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
.contentLeft {
  min-width: 35%;
}

@media (max-width: 1650px) {
  .contentAboutInfo {
    border-radius: 15.587px;
    /* background: #1D212D; */
    display: flex;
    column-gap: 40px;
    padding: 30px;
  }
  .contentLeft {
    min-width: 50%;
    height: auto;
    /* height: 800px; */
  }
  .contentLeft img {
    width: 100%;
  }
}
@media (max-width: 1350px) {
  .productsSection {
    padding: 45px 30px 0 30px;
  }
}
@media (max-width: 1300px) {
  .fruit {
    font-size: 40px;
  }
  .fruitSort {
    font-size: 35px;
  }
}
@media (max-width: 1200px) {
  /* .contentAboutInfo {
        flex-direction: column;
    }
    .contentRight {
        max-width: 100%;
    }
    .content:nth-child(even) .contentAboutInfo {
        flex-direction: column;
    } */
  .contentAboutInfo {
    column-gap: 30px;
  }
  /* .descr {
        max-height: 130px;
        overflow-y: scroll;
        scrollbar-color: dark;
    } */
  .rightTop {
    position: relative;
    justify-content: start;
  }
  .imgbox img {
    position: absolute;
    width: 210px;
    height: 200px;
    top: -7%;
  }
  .contentLeft {
    max-height: 40%;
    /* position: absolute; */
    background-image: unset;
  }
  .contentLeft img {
    max-width: 100%;
    display: block;
  }
}
@media (max-width: 1180px) {
  .descr {
    max-height: unset;
    overflow-y: unset;
  }
  .contentAboutInfo {
    flex-direction: column;
    row-gap: 15px;
    padding: 10px;
  }
  .contentRight {
    max-width: 100%;
    margin-left: unset;
  }
  .contentLeft {
    margin-right: unset;
  }
  .rightTop {
    justify-content: center;
    align-items: center;
  }
  .content:nth-child(even) .contentAboutInfo {
    flex-direction: column;
  }

  .infoBox {
    row-gap: 20px;
  }
  .descr {
    font-size: 14px;
  }
  .imgbox img {
    position: unset;
    transform: none;
    width: 240px;
    height: 230px;
    /* top: -7%; */
  }
}
@media (max-width: 768px) {
  .productsSection {
    padding: 30px 15px 0 15px;
  }
  .imgbox img {
    position: unset;
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 550px) {
  .fruit {
    font-size: 30px;
  }
  .fruitSort {
    font-size: 24px;
  }
  .imgbox img {
    position: unset;
    width: 138.97px;
    height: 139.035px;
  }
}
