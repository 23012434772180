.whyUsSecion {
  padding: 83px 0 157px 65px;
  background: #f5f5f5;
  overflow: hidden;
  background-image: url(../../assets/Ellipse\ 426.png);
  background-position: left calc(100% - -181px);
  background-repeat: no-repeat;
}
@media (max-width: 1150px) {
  .whyUsSecion {
    margin-bottom: 20px;
  }
}
.whyUsContent {
  display: flex;
  justify-content: space-between;
}
.whyUsContentLeft {
  min-width: 425px;
  width: 29%;
  /* padding: 56px 0 135px 0; */
  padding: 56px 0 0 0;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.whyUsContentLeftTitle {
  color: #eb1153;
  /* font-family: "AeonikTRIALRegular"; */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 18px */
}
.arrowspan {
  height: 49px;
  width: 49px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactUsBtn:hover {
  color: #eb1153;
}
.arrowspan:hover {
  background-color: #eb1153;
}
.arrowspan svg path {
  fill: black;
}
.arrowspan:hover svg path {
  fill: #fff !important;
}
.btnLeft {
  transform: rotate(180deg);
}
.whyUsContentLeftTitle::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 7px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  /* background-color: #3FAA0B; */
  -webkit-animation: glowing 1300ms infinite;
  -moz-animation: glowing 1300ms infinite;
  -o-animation: glowing 1300ms infinite;
  animation: glowing 1300ms infinite;
}
@-webkit-keyframes glowing {
  0% {
    background-color: #3faa0b;
    -webkit-box-shadow: 0 0 3px #0091b2;
  }
  50% {
    background-color: #409217;
    -webkit-box-shadow: 0 0 15px #409217;
  }
  100% {
    background-color: #398812;
    -webkit-box-shadow: 0 0 3px #398812;
  }
}
@keyframes glowing {
  0% {
    background-color: #3faa0b;
    box-shadow: 0 0 3px #3faa0b;
  }
  50% {
    background-color: #409217;
    box-shadow: 0 0 15px #409217;
  }
  100% {
    background-color: #398812;
    box-shadow: 0 0 3px #398812;
  }
}
.whyUsContentLeftConent {
  margin-top: 90px;
  margin-right: 10px;
  /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
  /* max-width: 300px; */
}
.whyUsContentLeftConent p {
  color: #525252;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.whyUsContentLeftConentBold {
  font-weight: 700 !important;
}
.whyUsContentRight {
  /* padding-left: 145px; */
  /* padding-left: 114px; */
  /* width: -webkit-fill-available; */
  width: 69%;
}
.whyUsContentRightTitle {
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
}
.whyUsContentRightTop {
  padding-right: 68px;
  display: flex;
  justify-content: space-between;
}
.slicSliderContent {
  margin-top: 57px;
}
.sliderBtns {
  display: flex;
  column-gap: 25px;
}
.sliderBtns span {
  cursor: pointer;
}
.sliderBtns span:hover svg path {
  fill: #eb1153;
}

.slick-slide {
  box-sizing: border-box;
  max-width: 428px;
  width: 428px;
}
.slick-track {
  display: flex;
}
.slick-slide.slick-active {
  margin-right: 22px !important;
}
.slick-slide:last-child {
  margin-right: 0;
}
.slick-track {
  padding: 10px;
}
.whyUsContentRightTitleMobile {
  display: none;
}
@media (max-width: 1350px) {
  .whyUsContentRightTop {
    justify-content: start;
    column-gap: 30px;
  }
  .whyUsContentRightTitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
  }
}
@media (max-width: 1150px) {
  .whyUsSecion {
    padding: 0 30px 0 30px !important;
    background-image: unset;
  }
  .whyUsContentRightTitle {
    margin-top: 23px;
  }
  .whyUsContent {
    flex-direction: column;
  }
  .whyUsContentLeftConent {
    display: none;
  }
  .whyUsContentRight {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .whyUsContentRightTitle {
    display: none;
  }
  .whyUsContentRightTitleMobile {
    display: block;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%; /* 21.6px */
    margin-top: 23px;
  }
  .sliderBtns {
    display: none;
  }
  .whyUsSecion {
    padding: 0 15px 0 15px !important;
    background-image: unset;
  }
}
.currentSlide {
  transform: scale(0.6); /* You can adjust the scale factor as needed */
}
@media (max-width: 768px) {
  .whyUsSecion {
    height: 663px;
  }
}
@media (max-width: 430px) {
  .whyUsSecion {
    height: auto;
  }
  .slick-slide {
    height: 100%;
  }
}
