.aboutUsSection {
    color: #1D212D;
    overflow: hidden;
    max-width: 100%;
}

.aboutContent {
    /* padding:85px 69px 0 0; */
}

.aboutContent {
    display: flex;
    flex-direction: column;
    height: auto;
    /* or set a specific height for your sections */
}

.firstMaskContent {
    display: flex;
    padding: 85px 69px 85px 0;
}

.aboutContent {
    display: flex;
    flex-direction: column;
    height: auto;
    /* or set a specific height for your sections */
}

.secondMaskContent {
    display: flex;
    flex-direction: row-reverse;
    padding: 85px 0 85px 69px;
    /* min-height: 100vh; */
}

/* .certificatesRef, .ReadyToTake, .whyUs, .brands {
    min-height: 100vh;
} */
.contentLeft {
    position: relative;
    width: -webkit-fill-available;
    mask-image: url("../../assets/maskImg1.png");
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: -180px;
}

/* @media screen and (max-width: 767px) {
    background-image: url("../../assets/mobile-aboutUsimg3.jpeg");
} */
.secondMaskContent .contentLeft {
    position: relative;
    width: -webkit-fill-available;
    mask-image: url("../../assets/maskImg2.png");
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: calc(100% - -121px); 
}

.contentLeft img{
    position: fixed;
    top: 0;

}


.contentRight {
    max-width: 60%;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
}

.contentRight .rightTitle h1 {
    color: #1D212D;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 40px */

}

.contentText {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    font-size: 20px;
    line-height: 100%;
    /* 20px */
}

.contentTitle {
    font-weight: 700;
    color: #1D212D;
}

.title h1 {
    display: none;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 40px */
    /* font-family: "AeonikTRIALLight"; */
}

#brands {
    margin-top: 128px;
}

@media (max-width:1550px) {

    .contentLeft {
        mask-size: 100%;
        background-size: unset;
    }

    .secondMaskContent .contentLeft {
        mask-size: 100%;
        background-size: unset;
    }
}

@media (max-width:1350px) {
    .firstMaskContent {
        padding-right: 30px;
    }

    .contentLeft {
        mask-size: 120%;
        background-size: unset;
    }

    .secondMaskContent .contentLeft {
        mask-size: 120%;
        background-size: unset;
    }

    .contentText {
        font-size: 18px;
    }

    .contentRight .rightTitle h1 {
        font-size: 30px;
    }

    .contentText {
        row-gap: 15px
    }

    .contentRight {
        row-gap: 30px
    }
}

@media (max-width:1150px) {
    .firstMaskContent {
        flex-direction: column;
        padding-left: 30px;
        row-gap: 30px;
        padding-bottom: 53px;
    }

    .secondMaskContent {
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;
        row-gap: 30px;
        padding-bottom: 53px
    }

    .contentLeft {
        height: 500px;
        mask-size: contain;
        mask-position: center;
        background-size: cover;
        background-position: center;
    }

    .contentRight {
        max-width: 100%;
    }

    .secondMaskContent .contentLeft {
        height: 500px;
        mask-size: contain;
        mask-position: center;
        background-size: cover;
        background-position: right;
    }

    .title h1 {
        display: block;
        line-height: 100%;
        /* margin-bottom: 30px; */
    }

    .rightTitle {
        display: none;
    }
}

@media (max-width:768px) {
    .firstMaskContent {
        padding-left: 15px;
    }

    .secondMaskContent {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 0;
    }

    .title h1 {
        font-size: 24px;
        line-height: 100%;
    }

    .contentLeft {
        height: 500px;
        mask-size: contain;
        mask-position: center;
        background-size: cover;
        background-position: center;
    }

    .secondMaskContent .contentLeft {
        height: 500px;
        mask-size: contain;
        mask-position: center;
        background-size: cover;
        background-position: right;
    }

    #brands {
        margin-top: 40px;
    }
}

@media (max-width:430px) {
    .contentLeft {
        height: 350px;
        mask-size: contain;
        mask-position: center;
        background-size: cover;
        background-position: center;
    }

    .title h1 {
        font-size: 24px;
        line-height: 100%;
    }

    .contentText {
        font-size: 14px;
    }
}