.ecosystemSection {
    padding: 85px 69px;
    color: #1D212D;
    background:#F5F5F5;
    /* overflow-y: scroll; */
}
.scrollableElem {

}
.ecosystemContent {
    border-radius: 5px;
    background: #FFF;
    padding: 44px 0;
    display: flex;
}
.leftContent {
    padding : 0 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 422px;
}
.leftContent img {
    
}
/* .isLastComp {
    position: unset !important;
    visibility: visible !important;
    opacity: 1 !important;
} */
/* .firstComp {
    position: unset !important;
    visibility: visible !important;
    opacity: 1 !important;
} */
.rightContent {
    padding:0 56px 0 64px;
}
.brandName {
    font-size: 30px;
    /* font-weight: 700; */
    font-family: "AeonikTRIALBold";
    line-height: 100%; /* 30px */
    margin-bottom: 32px;
}
.brandInfo p{
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    line-height: 100%; /* 20px */
}
.infoTitle {
    font-weight: 700;
    font-family: "AeonikTRIALBold";
}
.brandInfo:nth-child(2){
    margin-bottom: 20px;
}
.brandsMobile {
    display: none;
}
@media (max-width:1350px) {
    .ecosystemSection {
        padding: 85px 30px;
    }
}

@media (max-width:1150px) {
    .scrollableElem {
        display: none;
    }
    .brandsMobile {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }
    .ecosystemContent {
        padding: 30px 0 ;
    }
    .leftContent {
        padding: 0 60px;
        max-width: 35%;
    }
    .leftContent img {
        /* width: 100%; */
    }
    .rightContent {
        padding:0 30px 0 30px;
    }
}
@media (max-width:1000px) {
    .ecosystemContent {
        border-radius: 10px;
        flex-direction: column;
        row-gap: 30px;
    }
    .ecosystemSection {
        padding: 30px 15px;
    }
    .leftContent {
        padding: 0 60px;
        max-width: 100%;
    }
}
@media (max-width:768px) {
    .ecosystemSection {
        padding: 30px 15px;
    }
    .brandName {
        font-size: 16px;
    }
    .infoTitle, .brandInfo {
        font-size: 14px;
    }
}