.brandsSection {

    margin-top: 50px;

}

.brandsSectionContent {
    max-width: 1400px;
    width: 100%;
    margin: 0px auto;
    overflow: hidden;
}

.slideItem {
    width: 100%;
    padding: 0 50px;
    height: 60px;
}


.slideItem img {
    height: 60px;
    max-width: 219px !important;
    width: 100%;
}

@media (max-width:768px) {
    .slideItem {
        padding: 0 10px;
        height: 60px;
    }

    .slideItem img {
        height: 60px;
        max-width: 219px !important;
        width: 100%;
    }

    .brandsSectionContent {
        max-width: 600px;
        height: 60px;
        width: 100%;
        margin: 0px auto;
    }
}

