.slideItem {
  padding: 29px 22px 29px 22px;
  background: #fff;
  max-width: 428.833px;
  width: 428.833px;
  border-radius: 3.723px;
  width: 428px;
  margin: 10px 11px;
  transition: transform 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 463px;
  cursor: pointer;
}
.slideItembody {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.currentSlide {
  transform: scale(0.6); /* You can adjust the scale factor as needed */
}
.slideItem:hover {
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
}
.slideItem:hover .slideItembodyTitle {
  color: #eb1153;
}
.slideItemTop {
  margin-bottom: 19px;
}
.slideItemTopBox {
  padding: 35px 116px;
  width: 100%;
  border-radius: 7.445px;
  background: #f5f5f5;
}
.slideItemTopBox img {
  width: 148.943px;
  height: 98.633px;
}
.slideItembodyTitle {
  color: #373737;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.slideItemConentTitle {
  flex-grow: 1;
  color: #3faa0b;
  font-size: 14px;
  font-weight: 700;
  margin-top: 20.5px;
}
.slideItemConentText {
  color: #525252;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.slideItemConent {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}

@media (max-width: 1350px) {
  .slideItem {
    width: 302px;
  }
  .slideItem:nth-child(1) {
    margin-left: 0;
  }
  .slideItemTopBox {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 30px;
  }
}
@media (max-width: 768px) {
  .slideItem {
    height: 400px;
  }
}
@media (max-width: 430px) {
  .slideTitleContainer {
    flex: unset;
  }
  .slideItemTopBox img {
    width: 90px;
    height: 59px;
  }
  .slideItem {
    border-radius: 10px;
    padding: 5px;
    width: 172px;
    height: 100%;
    margin: 0 15px;
  }
  .slideItemTop {
    margin-bottom: 15px;
  }
  .slideItembodyTitle {
    font-size: 10px;
  }
  .slideItemConentTitle {
    font-size: 9px;
  }
  .slideItemConentText {
    font-size: 8px;
  }
}
@media (min-width: 1357px) {
  .slideItembodyTitle {
    font-size: 26px;
  }
  .slideItemConentTitle {
    font-size: 14px;
  }
  .slideItemConentText {
    font-size: 12px;
  }
}
@media (max-width: 1350px) {
  .slideItemConentTitle {
    font-size: 12px;
  }
}
@media (max-width: 424px) {
  p {
    font-size: 10px !important;
  }
}