@import "./fonts.scss";
@import url(https://db.onlinewebfonts.com/c/0927e08fbdf95205b63c5b8774adeef6?family=Aeonik+TRIAL);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 300ms ease-out;
}
body,
html {
  font-family: "MontserratRegular", Arial, Helvetica, sans-serif;
}
.App {
  background: #f5f5f5;
  max-width: 100%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .brandsSection .slick-slide {
    margin-left: 0;
  }
}
.slick-current {
  transition: transform 0.3s ease; /* Adjust the timing as needed */
}
