.video_section {
  padding: 30px 40px 0px 40px;
  position: relative;
}
.video_section img {
  width: 100%;
  height: 570px;
  cursor: pointer;
  border-radius: 10px;
}
.video_coverimage {
  width: 100%;
  height: unset !important;
}
.video_section .button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 257px;
  max-height: 257px;
}
.button > img {
  width: 100%;
  height: unset !important;
}
@media (max-width: 1060px) {
  .video_section .button {
    padding: 30px 40px 0px 40px;
  }
}
@media (max-width: 768px) {
  .video_section img {
    width: 100%;
    height: 450px;
  }
  .video_section .button {
    max-width: 227px;
    max-height: 227px;
  }
}
@media (max-width: 530px) {
  .video_section img {
    width: 100%;
    height: 300px;
  }
  .video_section .button {
    max-width: 200px;
    max-height: 200px;
  }
}
@media (max-width: 400px) {
  .video_section img {
    width: 100%;
    height: 330px;
  }
  .video_section .button {
    max-width: 180px;
    max-height: 180px;
  }
}
@media (max-width: 375px) {
  .video_section img {
    width: 100%;
    height: 280px;
  }
  .video_section .button {
    max-width: 120px;
    max-height: 120px;
  }
}
@media (max-width: 550px) {
  .video_section {
    padding: 30px 20px 0px 20px;
    position: relative;
  }
  .video_section .button {
    max-width: 157px;
    max-height: 157px;
  }
  .button {
    padding: 30px 20px 0px 20px;
  }
}
