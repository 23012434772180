.packagingSection{
    color: #1D212D;
    background:#F5F5F5;
    margin-bottom: 85px;
}
.packagingContent {
    padding: 85px 220px 0 220px;
    display: flex;
    flex-direction: column;
    row-gap: 85px;
}
.packagingContentTitle {
    font-size: 36px;
    font-style: normal;
    font-family: "AeonikTRIALBold";
    font-weight: 700;
    line-height: 100%;
}
.imagesbox {
    display:flex;
    column-gap:30px
}
.imgBox {
    /* flex:30% */
}
.imgBox img {
    width: 100%;
    height: 100%;
}
.contentText {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 20px */
}
.contentTextSecond {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 20px */
    display:flex;
    flex-direction: column;
    row-gap: 20px;
}
.infoTitle {
    font-weight: 700;
    /* font-family: "AeonikTRIALBold"; */
}
.infoTitleBold {
    font-family: "AeonikTRIALBold";
}
.infoBox {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}
.mobileImgBox {
    display: none;
}
.flexContent {
    display: flex;
    flex-direction:column;
    row-gap: 30px;
}
@media  (max-width:1350px) {
    .packagingContent {
        padding: 30px;
        row-gap: 50px;
    }
}
@media (max-width:768px) {
    p {
        font-size: 14px;
    }
    .packagingContent {
        row-gap: 30px;
    }
    .imagesbox .imgBox:nth-child(3){
        display: none;
    }
    .imgBoxHidding{
        display: none;
    }
    .contentText {
        /* display: none; */
    }
    .packagingContentTitle {
        text-align: center;
        font-family: "AeonikTRIALRegular";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }
    .contentTextSecond, .infoTitle {
        font-size: 14px;
    }
    .flexContent {
        display: flex;
        flex-direction:column-reverse;
        row-gap: 30px;
    }
    .hideingBox {
        display: none;
    }
    .mobileImgBox {
        display: block;
    }
}