.stayInKnowSection {
  padding: 160px 65px 0 65px;
  background-color: #f5f5f5;
  color: #fff;
  display: none;
}
.stayInKnowContent {
  display: flex;
  column-gap: 30px;
}
@media (max-width: 1350px) {
  .stayInKnowSection {
    padding: 160px 30px 0 30px;
  }
  .stayInKnowContent {
    flex-wrap: wrap;
  }
}
@media (max-width: 1210px) {
  .stayInKnowContent {
    justify-content: start;
    flex-wrap: wrap;
    row-gap: 30px;
  }
}
@media (max-width: 820px) {
  .stayInKnowContent {
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .stayInKnowSection {
    padding: 50px 15px 0 15px;
  }
}
