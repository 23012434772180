.contacsSection {
  color: #fff;
}

.contactsContent {
  padding: 30px;
  display: flex;
}

.contentRight {
  width: 32%;
  padding: 30px;
  background-image: url("../../assets/subscribe_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.contentRightTitle {
  font-size: 29.582px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 43px;
}

.formBox {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

::placeholder {
  color: rgba(255, 255, 255, 0.3);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inputBox {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.inputBox p {
  color: #eb1153;
}

.terms {
  color: #eb1153;
  margin-top: -24px;
}

.inputBoxCheckbox {
  display: flex;
  column-gap: 20px;
  max-width: 300px;
}

.inputBox label {
  /* opacity: 0.8; */
  font-family: "AeonikTRIALLight";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inputBox input,
.inputBox textarea {
  padding: 5px;
  color: #fff;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  max-width: 380px;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
}

.inputBoxCheckbox label {
  font-family: "AeonikTRIALLight";
  opacity: 0.8;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.inputBoxCheckbox input {
  position: relative;
  cursor: pointer;
  height: 0;
  /* width: 1.9rem;
    height: 1.9rem;
    accent-color: #008210; */
}

.inputBoxCheckbox input:before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  top: 5px;
  left: 0;
  border: 1px solid #99afc1;
  border-radius: 3px;
  padding: 1px;
  background-color: #008210;
}
.inputError::before {
  border: 1px solid red !important;
}
.inputBoxCheckbox input[type="checkbox"]:checked:before {
  background-color: #008210;
  border-color: #99afc1 !important;
}

.inputBoxCheckbox input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 7px;
  left: 7px;
}

.formButton {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 20px 25px;
  border-radius: 1000px;
  background: rgba(255, 255, 255, 0.3);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  transition: all 300ms ease-out;
  cursor: pointer;
  min-width: 174px;
}

.formButton:hover {
  background: #eb1153;
}

.mobiletitle {
  display: none;
}

.mapContent {
  max-width: 70%;
  width: 70%;
  position: relative;
}

@media (max-width: 1024px) {
  .contentRightTitle {
    font-size: 18px;
  }
}

.cart {
  max-width: 483px;
  width: 100%;
  /* height: 400px; */
  background: #ffffffe7;
  position: absolute;
  top: 69px;
  left: 99px;
  z-index: 9999;
  color: #1d212d;
  padding: 60px 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}
.cartTexts {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.phone {
  display: inline-block;
  width: 44px;
  height: 50px;
  border-radius: 10px;
  background-color: #c4c4c433;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.phone img {
  width: 25px;
  height: 30px;
}
.phoneNumber {
  display: flex;
  align-items: center;
  margin-top: 62px;
}

.phoneNumber > span:hover {
  background-color: #eb1153;
}

.phonenumbertext a {
  font-size: 30px;
  color: inherit;
  text-decoration: unset;
}

.phonenumbersubtext {
  font-size: 18px;
}

.cartTitle {
  font-size: 50px;
  margin-bottom: 3px;
}

.cart > :nth-child(2) {
  max-width: 102px;
  font-size: 28px;
  margin-bottom: 22px;
}

.social {
  margin-top: 59px;
  display: flex;
}

.contentRight {
  max-width: 750px;
  width: 100%;
}

.social > span:hover {
  background-color: #eb1153;
}

.social > :nth-child(1) {
  margin-right: 13px;
  width: 44px;
  height: 50px;
  border-radius: 10px;
  background-color: #c4c4c433;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social > :nth-child(1) img,
.social > :nth-child(2) img {
  width: 25px;
  height: 30px;
}
.social > :nth-child(2) {
  display: inline-block;
  width: 44px;
  height: 50px;
  border-radius: 10px;
  background-color: #c4c4c433;
  margin-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1508px) {
  .cart {
    max-width: 400px;
    padding: 60px 52px;
    height: 420px;
    top: 49px;
    left: 69px;
  }
  .phonenumbertext a {
    font-size: 26px;
  }
}
@media (max-width: 1338px) {
  .cart {
    max-width: 320px;
    padding: 60px 20px;
    height: 300px;
    top: 29px;
    left: 29px;
  }
  .cartTitle {
    font-size: 30px;
    margin-bottom: 3px;
  }
  .phonenumbertext a {
    font-size: 20px;
  }
  .phonenumbersubtext {
    font-size: 12px;
  }
  .phoneNumber {
    margin-top: 15px;
  }

  .phone {
    width: 40px;
    height: 40px;
  }
  .phone img {
    width: 15px;
    height: 20px;
  }
  .social > :nth-child(1) img,
  .social > :nth-child(2) img {
    width: 25px;
    height: 20px;
  }
  .social > :nth-child(1) {
    width: 40px;
    height: 40px;
  }
  .social > :nth-child(2) {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 992px) {
  .phone img {
    width: unset;
    height: unset;
  }
  .cart {
    max-width: 264px;
    width: 100%;
    height: 230px;
    background: #ffffffe7;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 9999;
    color: #1d212d;
    padding: 14px 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cartTexts {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .phone {
    display: inline-block;
    width: 26px;
    height: 26px;
    border-radius: 10px;
    background-color: #c4c4c433;
    margin-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .phoneNumber {
    display: flex;
    align-items: center;
    margin-top: 24px;
  }
  .phonenumbertext a {
    font-size: 10px;
  }

  .phonenumbersubtext {
    font-size: 6px;
  }

  .cartTitle {
    font-size: 24px;
    margin-bottom: 3px;
  }

  .cart > :nth-child(2) {
    max-width: 102px;
    font-size: 12px;
    margin-bottom: 22px;
  }
  .social {
    margin-top: 15px;
    display: flex;
  }

  .social > :nth-child(1) {
    margin-right: 13px;
    width: 30px;
    height: 30px;
    border-radius: 10px;
    background-color: #c4c4c433;
    margin-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .social > :nth-child(2) {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 10px;
    background-color: #c4c4c433;
    margin-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .contacsSection {
    padding: 50px 25px 0px 25px;
    margin-bottom: 50px;
  }
  .mapContent {
    max-width: 750px;
    width: 100%;
    height: 363px;
    position: relative;
  }

  .contentRight {
    max-width: 750px;
    margin-bottom: 50px;
    width: 100%;
  }

  .contactsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    padding: unset;
  }

  .contentRightTitle {
    font-size: 16px;
  }

  .formButton {
    color: #fff;
    font-size: 16px;
    padding: 13px 16px;
  }
}

@media (max-width: 393px) {
  .cart {
    display: block;
    max-width: 172px;
    width: 100%;
    height: 177px;
  }

  .mapContent {
    max-width: 363px;
    width: 100%;
    height: 250px;
    position: relative;
  }

  .cartTexts > :nth-child(1) {
    font-size: 24px;
    margin-bottom: 3px;
  }

  .cartTexts > :nth-child(2) {
    max-width: 102px;
    font-size: 12px;
    margin-bottom: 22px;
  }

  .phoneNumber {
    margin-top: unset;
  }
}

@media (max-width: 900px) {
  .formButton {
    min-width: 100px;
  }
  .contentRight {
    background-image: url("../../assets/mobileFormBg.png");
  }
}

@media (max-width: 620px) {
  .contentRightTitle {
    margin-bottom: 30px;
  }

  .inputBox label {
    font-size: 10px;
  }

  .inputBox input {
    font-size: 18px;
  }

  ::placeholder {
    font-size: 18px;
  }

  .inputBoxCheckbox {
    column-gap: 10px;
  }

  .inputBoxCheckbox input:before {
    height: 11px;
    width: 11px;
  }

  .inputBoxCheckbox label {
    font-size: 10px;
  }

  .formButton {
    padding: 13px 16px;
    font-size: 12px;
    margin: 0 auto;
  }

  .formButton svg {
    width: 15px;
  }
}
