:root {
  --apricot: #d0a80b;
  --nectar: #f7abaf;
  --flat-peach: #f27271;
  --plum: #9e329f;
  --peach: #bd6220;
}

.interactiveMap {
  min-height: 90vh;
  overflow: hidden;
  background-color: #1d212d;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .interactiveMap {
    min-height: auto;
    flex-direction: column-reverse;
  }
}

.interactiveMap .map {
  width: 50vw;
  position: relative;
  padding: 24px;
}

@media (max-width: 768px) {
  .interactiveMap .map {
    width: 100vw;
    margin-top: 5vh;
  }
}

.interactiveMap .map .hand {
  position: absolute;
  top: 0;
  left: 0;
  width: 35%;
  height: 40%;
}

@media (max-width: 768px) {
  .interactiveMap .map .hand {
    display: none;
  }
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.interactiveMap .map .zoom-animation {
  animation: zoomInOut 2s infinite;
}

.interactiveMap .container {
  width: 50vw;
}

@media (max-width: 768px) {
  .interactiveMap .container {
    width: 100vw;
    margin-top: -25vh;
  }
}

@media (max-width: 480px) {
  .interactiveMap .container {
    width: 100vw;
    margin-top: -20vh;
  }
}

.interactiveMap .circle-carousel {
  position: relative;
  padding-top: 100%;
  transform: scale(1.5) translate(10vw, -15vh);
}

@media (max-width: 768px) {
  .interactiveMap .circle-carousel {
    transform: scale(1);
  }
}

.interactiveMap .circle-carousel .slides {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.interactiveMap .circle-carousel .slide {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  padding-top: 10vh;
}
@media (max-width: 768px) {
  .interactiveMap .circle-carousel .slide {
    padding-top: 17vh;
  }
}
.interactiveMap .circle-carousel .slide .slide-content {
  color: white;
  text-align: center;
  margin-right: 20px;
}
@media (max-width: 768px) {
  .interactiveMap .circle-carousel .slide .slide-content {
    max-width: 30vw;
    margin-right: 10px;
  }
}
.interactiveMap .circle-carousel .slide .slide-content .name {
  font-size: 45px;
  line-height: 90%;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .interactiveMap .circle-carousel .slide .slide-content .name {
    font-size: 14px;
    margin-bottom: 4px;
  }
}
.interactiveMap .circle-carousel .slide .slide-content .variety {
  font-size: 35px;
  font-weight: 300;
  line-height: 90%;
  margin-bottom: 16px;
}
@media (max-width: 768px) {
  .interactiveMap .circle-carousel .slide .slide-content .variety {
    font-size: 10px;
    margin-bottom: 4px;
  }
}
.interactiveMap .circle-carousel .slide .slide-content .options {
  font-size: 12px;
  font-weight: 300;
  line-height: 120%;
}
@media (max-width: 768px) {
  .interactiveMap .circle-carousel .slide .slide-content .options {
    font-size: 8px;
    margin-bottom: 4px;
  }
}
.interactiveMap .circle-carousel .slide .slide-content .link {
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.interactiveMap
  .circle-carousel
  .slide
  .slide-content
  .link
  > a
  > svg
  > circle {
  fill: #fff;
}

.interactiveMap .circle-carousel .slide .slide-content .link > a > svg > path {
  fill: #1d212d;
}

.interactiveMap .circle-carousel .slide .slide-content .link > a:hover {
  color: #eb1153;
}
.interactiveMap
  .circle-carousel
  .slide
  .slide-content
  .link
  > a:hover
  > svg
  > circle {
  fill: #eb1153;
}
.interactiveMap
  .circle-carousel
  .slide
  .slide-content
  .link
  > a:hover
  > svg
  > path {
  fill: #fff;
}

@media (max-width: 768px) {
  .interactiveMap .circle-carousel .slide .slide-content .link {
    margin-top: 6px;
  }
}
.interactiveMap .circle-carousel .slide .slide-content .link a {
  color: white;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 300;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
@media (max-width: 768px) {
  .interactiveMap .circle-carousel .slide .slide-content .link a {
    font-size: 8px;
    margin-right: 0px;
    gap: 0;
  }
}
@media (max-width: 768px) {
  .interactiveMap .circle-carousel .slide .slide-content .link svg {
    transform: scale(0.5);
  }
}
.interactiveMap .circle-carousel .slide .slide-image {
  position: relative;
}
.interactiveMap .circle-carousel .slide .slide-image .shadow {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1350px) {
  .interactiveMap .circle-carousel .slide .slide-image {
    display: none;
  }
}
@media (max-width: 768px) {
  .interactiveMap .circle-carousel .slide .slide-image {
    max-width: 25vw;
    display: block;
  }
  .interactiveMap .circle-carousel .slide .slide-image .shadow {
    transform: scale(0.4);
  }
}

.interactiveMap .circle-carousel .slide.active {
  z-index: 1;
  opacity: 1;
}
.interactiveMap .circle-carousel .pagination {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  transition-property: transform;
  transition-timing-function: ease-out;
  pointer-events: none;
  user-select: none;
}
.interactiveMap .circle-carousel .pagination .dot {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%) rotate(100deg);
  width: 140px;
  height: 140px;
  border-radius: 50%;
  pointer-events: auto;
  transition: 0.3s;
  cursor: pointer;
}

@media (max-width: 768px) {
  .interactiveMap .circle-carousel .pagination .dot {
    transform: translate(-50%, -50%) rotate(-180deg);
  }
}

@media (max-width: 1350px) {
  .interactiveMap .circle-carousel .pagination .dot {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 1150px) {
  .interactiveMap .circle-carousel .pagination .dot {
    width: 90px;
    height: 90px;
  }
}
@media (max-width: 768px) {
  .interactiveMap .circle-carousel .pagination .dot {
    width: 60px;
    height: 60px;
  }
}
.interactiveMap .circle-carousel .pagination .item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  stroke-opacity: 0.8;
}
