.certificatesSection{
 margin-bottom: 0px;
}
.certificatesContent {
    display: none;
    padding: 115px 65px 115px 65px;
}
.contentLeft {
    max-width: 33%;
    min-width: 33%;
    display:flex;
    flex-direction: column;
    row-gap: 114px;
}
.conentTitle {
    color: #EB1153;
    /* font-family: "AeonikTRIALRegular"; */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 18px */
}
.contentLeft h1{
    color: #1D212D;
    font-size: 50px;
    line-height: 90%; /* 45px */
}

.conentTitle::before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 7px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    /* background-color: #3FAA0B; */
    -webkit-animation: glowing 1300ms infinite;
    -moz-animation: glowing 1300ms infinite;
    -o-animation: glowing 1300ms infinite;
    animation: glowing 1300ms infinite;
}
@-webkit-keyframes glowing {
    0% {
        background-color: #3FAA0B;
        -webkit-box-shadow: 0 0 3px #0091b2;
    }
    50% {
        background-color: #409217;
        -webkit-box-shadow: 0 0 15px #409217;
    }
    100% {
        background-color: #398812;
        -webkit-box-shadow: 0 0 3px #398812;
    }
}
@keyframes glowing {
    0% {
        background-color: #3FAA0B;
        box-shadow: 0 0 3px #3FAA0B;
    }
    50% {
        background-color: #409217;
        box-shadow: 0 0 15px #409217;
    }
    100% {
        background-color: #398812;
        box-shadow: 0 0 3px #398812;
    }
}
.contentRight {
    overflow: hidden;
}
.slideItem {
    padding: 0 20px;
    margin: 0 20px;
    /* width: 235px !important; */
}
.slideItem img {
    width: 100%;
}
@media (max-width:1350px) {
    .certificatesContent {
        padding: 80px 30px 115px 30px;
    }
}
@media (max-width:1150px) {
    .certificatesContent {
        flex-direction: column;
        padding: 80px 30px 115px 30px;
        row-gap:15px
    }
    .contentLeft {
        max-width: 100%;
        row-gap: 30px;
    }
    .contentLeft h1 {
        font-size: 35px;
    }
    .contentRight {
        padding: 15px 0 15px 0;
    }
    .slideItem:nth-child(1){
        padding-left: 0;
        margin-left: 0;
    }
}
@media (max-width:768px) {
    .certificatesContent {
        flex-direction: column;
        padding: 50px 15px 30px 15px;
    }
    .contentLeft h1 {
        font-size: 24px;
    }
    .slideItem {
        padding: 0 20px;
        /* width: 171px !important; */
        /* height: 222px; */
    }
}
@media (max-width:430px) {
    .slideItem {
        margin:0 22px;
    }
    .slideItem img {
        width: 100%;
    }
    .certificatesContent {
        flex-direction: column;
        padding: 50px 15px 30px 15px;
    }
}