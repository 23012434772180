.closeModal {
    display: flex;
    color: #EB1153;
    flex-direction: row-reverse;
    margin-bottom: 20px;
    cursor: pointer;
}

.modalContent{
    background: white;
    padding: 32px;
    width: 850px;
    margin: 10px;
    color: black;
}
.modalInfo{
    margin: 80px 0 100px;
    text-align: center;
}
.modalContentFlex{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 40px;
}
.modalTitle{
    font-size: 40px;
    font-weight: 400;
    font-family: "AeonikTRIALRegular", Arial, Helvetica, sans-serif;
}
.modalText{
    font-size: 20px;
    font-weight: 400;
    font-family: "AeonikTRIALRegular", Arial, Helvetica, sans-serif;
}

@media (max-width:600px) {

    .modalImage{
        width: 250px;
    }
    .modalTitle{
        font-size: 30px;
    }
    .modalText{
        font-size: 18px;
        line-height: 1.6;
    }
    .modalInfo{
        margin: 25px 0;
    }
}
@media   (max-width: 470px) {
    .modalImage{
        width: 200px;
    }
}